const env = process.env.NEXT_PUBLIC_ENV!

export default {
  release: process.env.NOW_GITHUB_COMMIT_SHA!,
  env,
  coreDomain: process.env.NEXT_PUBLIC_COREDOMAIN!,
  shopDomain: process.env.NEXT_PUBLIC_SHOPDOMAIN!,
  accountDomain: process.env.NEXT_PUBLIC_ACCOUNTDOMAIN!,
  services: {
    address: {
      url: 'https://api.getAddress.io',
      key: process.env.NEXT_PUBLIC_SERVICES_ADDRESS_KEY!,
    },
    unsupported: process.env.NEXT_PUBLIC_UNSUPPORTED_POSTCODES_URL!,
    mentionMe: {
      domain: process.env.NEXT_PUBLIC_SERVICES_MENTIONME_DOMAIN!,
      partnerCode: process.env.NEXT_PUBLIC_SERVICES_MENTIONME_PARTNERCODE!,
    },
    oneTrust: {
      id: process.env.ONE_TRUST_ID,
    },
    rudderstack: {
      writeKey:
        env === 'production'
          ? '219L5T146EOLJX8dJPLwHABhroP'
          : '23xklIwRG7gvGXzkBwBTMqp0nzq',
      dataPlaneUrl: 'https://allplantsubu.dataplane.rudderstack.com',
    },
    prismicRepo: 'allplants-cms-prod',
  },
  disruptionBanner: process.env.NEXT_PUBLIC_DISTRUPTION_BANNER,
  apiTimeout: process.env.NEXT_PUBLIC_API_TIMEOUT
    ? parseInt(process.env.NEXT_PUBLIC_API_TIMEOUT, 10)
    : 3000,
  featureToggle: {
    disableBlueprintRequests:
      process.env.NEXT_PUBLIC_FT_DISABLEBLUEPRINT === 'true',
  },
  seo: {
    baseUrl: 'https://secure.allplants.com',
    imageUrl:
      'https://prismic-io.s3.amazonaws.com/allplants-cms%2F8e19c6a7-fcf5-402c-b61a-2ece536e3866_delivery+box+1+-+allplants.jpg',
    companyName: 'allplants',
    description:
      'Eating more plants is the best way for people & planet to thrive, so we make it easy, exciting & delicious for everyone, delivering 5* meals, nationwide.',
    keywords: 'allplants, vegan, plant-based, meals, frozen, delivered',
    title: 'allplants | checkout',
    twitterUser: '@allplants',
  },
  cookieKey: 'ap-client',
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN!,
  },
  stripe: {
    publicKey: process.env.NEXT_PUBLIC_STRIPE_PUBLICKEY!,
  },
  cookie: {
    secure: process.env.NEXT_PUBLIC_COOKIE_SECURE === 'true',
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  },
  analytics: {
    gtm: {
      id: process.env.NEXT_PUBLIC_GTM_ID,
    },
  },
  statsig: {
    client_sdk_key: process.env.NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY!,
  },
  uuid_cookie: 'session_a_uuid',
  apiKeyGoogleMaps: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAPS!,
}
