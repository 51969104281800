import Cookies from 'js-cookie'
import App from 'next/app'
import Head from 'next/head'
import { Toaster } from 'react-hot-toast'
import { StatsigProvider } from 'statsig-react'

import { DiscountProvider } from '@fe/components/checkout/DiscountInput'
import { OrderSummaryProvider } from '@fe/components/checkout/OrderSummary'
import config from '@fe/config'
import { Provider as CartProvider } from '@fe/providers/cart'
import { CheckoutFormDataProvider } from '@fe/providers/checkoutFormData'
import { DeliveryDaysProvider } from '@fe/providers/deliveryDaysManager'
import { Provider as FeatureDetectionProvider } from '@fe/providers/featureDetection'
import { IdentityProvider } from '@fe/providers/identity'
import { UserProvider } from '@fe/providers/userProvider'
import { trackEvent } from '@fe/services/analytics'
import { ThemeProvider, GlobalStyles } from '@fe/styles'
import defaultTheme from '@fe/styles/themes/default'

export function reportWebVitals(metric: any) {
  const { label, name, value } = metric

  // Send web vitals data to Amplitude as integers
  if (label === 'web-vital') {
    void trackEvent('web.vitals', {
      name,
      value: Math.round(name === 'CLS' ? value * 1000 : value),
    })
  }
}

export default class MyApp extends App<
  any,
  any,
  {
    hasError: boolean
    errorEventId: string | null
    sessionUUID: string | undefined
  }
> {
  constructor(props) {
    super(props)

    // Middleware will automatically set a cookie for the user if they visit a page
    const sessionUUID = Cookies.get(config.uuid_cookie)

    this.state = {
      hasError: false,
      errorEventId: null,
      sessionUUID,
    }
  }

  public static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    }
  }

  public static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true }
  }

  public render() {
    const { Component, pageProps } = this.props

    return (
      <>
        <Head>
          <title>{config.seo.title}</title>
        </Head>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <StatsigProvider
            options={{
              environment: { tier: config.env },
            }}
            sdkKey={config.statsig.client_sdk_key}
            user={{
              customIDs: {
                'Session UUID': this.state.sessionUUID!,
              },
            }}
            waitForInitialization={false}
          >
            <FeatureDetectionProvider>
              <CartProvider>
                <IdentityProvider>
                  <CheckoutFormDataProvider>
                    <DeliveryDaysProvider>
                      <DiscountProvider>
                        <OrderSummaryProvider>
                          <UserProvider>
                            <Toaster position='bottom-center' />
                            <Component {...pageProps} />
                          </UserProvider>
                        </OrderSummaryProvider>
                      </DiscountProvider>
                    </DeliveryDaysProvider>
                  </CheckoutFormDataProvider>
                </IdentityProvider>
              </CartProvider>
            </FeatureDetectionProvider>
          </StatsigProvider>
        </ThemeProvider>
      </>
    )
  }
}
