import getStatsigOptionsForRudderstackEvents from '@fe/utils/statsig'

import isRudderStackAvailable from './isRudderStackAvailable'

const statsigOptions = getStatsigOptionsForRudderstackEvents()
const rudderStackTrack = (eventName: string, body: any): void => {
  if (isRudderStackAvailable()) {
    window.rudderanalytics!.track(eventName, { ...statsigOptions, ...body })
  }
}

export default rudderStackTrack
