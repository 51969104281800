import Cookies from 'js-cookie'

import config from '@fe/config'

type TGetStatsigOptionsForRudderstackEvents = () => {
  statsigCustomIDs: Array<string>
  statsigEnvironment: {
    tier: string | undefined
  }
}

// Middleware will automatically set a cookie for the user if they visit a page
const sessionUUID = Cookies.get(config.uuid_cookie) || ''
const getStatsigOptionsForRudderstackEvents: TGetStatsigOptionsForRudderstackEvents =
  () => ({
    statsigCustomIDs: ['Session UUID', sessionUUID],
    statsigEnvironment: { tier: config.env },
  })

export default getStatsigOptionsForRudderstackEvents
